<template>
  <div style="background: #f6f6f6; height: 100%; position: relative;">
    <van-nav-bar title="购物车" :right-text="validShangpin.length ? status ? '管理' : '完成' : ''" left-arrow fixed placeholder
      @click-left="onClickLeft" @click-right="onClickRight" />

    <img class="kong" src="./img/kong.png" alt="" v-if="!validShangpin.length && !lose_shangpin.length">

    <div class="shop">
      <div class="shop-item" v-for="x in validShangpin" :key="x.shopId">
        <div class="shop-item-title">
          <span @click="enterStore">
            <van-image width="34" height="34" fit="cover" round :src="x.shopImg" />
          </span>
          <span @click="enterStore(x.shopId)" class="shop-item-title-shopName">{{ x.name }}</span>
          <span @click="enterStore(x.shopId)">
            <van-icon name="arrow" />
          </span>
        </div>

        <div class="shop-item-shangpin" v-for="(y, i) in x.list" :key="y.goodsSku.id"
          :style="{ borderBottom: i == x.list.length - 1 ? '0 solid #D8D8D8' : '1px solid #D8D8D8' }">
          <div class="shop-item-shangpin-checkbox">
            <span>
              <van-checkbox @click="checkbox(y)" v-model="y.shopCarIsCheck" checked-color="#E54320"></van-checkbox>
            </span>

            <div class="shop-xiangqing">
              <span>
                <van-image width="80" height="80" fit="cover" :src="y.picUrl" />
              </span>
              <div class="shop-xiangqing-miaoshu">
                <p class="shop-xiangqing-miaoshu-title">{{ y.spuName }}</p>
                <p>
                  <van-tag color="#EEE" text-color="#999">{{ y.specInfo }}</van-tag>
                </p>
                <p>
                  <span style="font-size: 0.3784rem; color: #FB2B53;">￥</span>
                  <span style="font-size: 0.4865rem; color: #FB2B53;">{{ y.goodsSku.salesPrice }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="shop-item-shangpin-stepper">
            <span></span>
            <span>
              <van-stepper @change="updateQuantity(y, $event)" disable-input v-model="y.quantity" integer min="1" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="shixiaoshangpin">
      <div class="shixiaoshangpin-title" v-if="lose_shangpin.length">
        <span style="font-size: 0.3784rem; color: #333;">失效的商品{{ lose_shangpin.length }}件</span>
        <span style="font-size: 0.3243rem; color: #FB2B53;" @click="clearLoseShangpin">清空失效商品</span>
      </div>

      <div class="shixiaoshangpin-item" v-for="x in lose_shangpin" :key="x.id">
        <span style="font-size: 0.3784rem; color: #999;">失效</span>
        <div class="shixiaoshangpin-item-right">
          <span>
            <van-image width="80" height="80" fit="cover" :src="x.picUrl" />
          </span>
          <div class="shixiaoshangpin-item-right-info">
            <p class="shixiaoshangpin-item-right-info-name" style="fong-size: 0.3784rem; color: #333;">{{
              x.goodsSpu.name
            }}</p>
            <p style="fong-size: 0.3784rem; color: #999;">宝贝已失效，不能购买</p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer" v-if="validShangpin.length">
      <span>
        <van-checkbox @click="allChecked" v-model="checked" checked-color="#E54320">全选</van-checkbox>
      </span>

      <div class="footer-total" v-show="status">
        <span>合计:</span>
        <span style="">￥{{ totalPrice }}</span>&nbsp;&nbsp;
        <span>
          <van-button round color="#E54320" size="small" @click="jiesuan">结算</van-button>
        </span>
      </div>

      <div class="footer-total" v-show="!status">
        <span></span>
        <span>
          <van-button round color="#E54320" size="small" @click="clearShangpin">删除</van-button>
        </span>
      </div>
    </div>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>
import { getYundianCart as getPage, delCart, updateQuantity } from '@/api/flMall'
import { Dialog, Toast } from 'vant'
import Loading from './actionSheet/Loading'
export default {
  components: { Loading },

  data () {
    return {
      status: true,
      value: 0,
      records: [],
      validShangpin: [], // 商品列表
      lose_shangpin: [],

      ids: [],
      ids2: [],
      shopIds: [],
      totalPrice: 0,
      loading: false,
      checked: false,
    }
  },

  created () {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
    this.getPage()
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },


  methods: {
    onClickRight() {
      this.status = !this.status
    },

    onClickLeft () {
      if (this.$route.query.type == 'h5') {
        this.$router.go(-1)
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },

    enterStore (id) {
      this.$router.push({
        path: '/ydMallStoreDetail',
        query: {
          id,
          type: 'h5'
        }
      })
    },

    getPage () {
      this.loading = true
      getPage().then(res => {
        if (res.data.code === 0) {
          this.loading = false
          const records = res.data.data.records
          // 失效商品
          const lose_shangpin = records.filter(x => {
            if (x.goodsSpu) {
              return x.goodsSpu.shelf == '0'
            }
          })
          this.lose_shangpin = lose_shangpin

          const valid_shangpin = records.filter(x => {
            if (x.goodsSpu) {
              return x.goodsSpu.shelf == '1'
            }
          })
          const copy_shangpin = JSON.parse(JSON.stringify(valid_shangpin))
          const validShangpin = this.create_valid_shangpin(copy_shangpin, 'shopId')
          const ids2 = JSON.parse(JSON.stringify(copy_shangpin)).filter(x => x.shopCarIsCheck).map(x => x.goodsSpu.id)
          const shopIds = Array.from(new Set(JSON.parse(JSON.stringify(copy_shangpin)).filter(x => x.shopCarIsCheck).map(x => x.shopId)))
          
          this.ids2 = ids2
          this.shopIds = shopIds
          this.validShangpin = validShangpin

          this.loading = false
          this.$nextTick(() => {
            this.calTotal()
            this.isAllChecked()
          })
        }
      }).catch(() => {
        this.loading = false
        Toast('服务繁忙')
      })
    },

    create_valid_shangpin (shangpin, shopId) {
      let map = {}
      let res = []
      for (let i = 0; i < shangpin.length; i++) {
        let ai = shangpin[i]
        if (!map[ai[shopId]]) {
          map[ai[shopId]] = [ai]
        } else {
          map[ai[shopId]].push(ai)
        }
      }

      Object.keys (map).forEach(key => {
        res.push({
          [shopId]: key,
          list: map[key],
          shopImg: map[key][0].shopInfo.imgUrl,
          name: map[key][0].shopInfo.name,
        })
      })
      return res
    },

    calTotal () {
      let goods = []
      let validShangpin = JSON.parse(JSON.stringify(this.validShangpin))
      validShangpin.forEach(x => {
        x.list.forEach(y => {
          if (y.shopCarIsCheck) {
            goods.push(y)
          }
        })
      })
      this.totalPrice = 0
      goods.forEach(x => {
        // 计算精度
        this.totalPrice = (((this.totalPrice * 100) + ((x.quantity * parseFloat(x.goodsSku.salesPrice)) * 100)) / 100).toFixed(2)
      })
    },

    // 判断是否全选
    isAllChecked () {
      let goods = []
      let validShangpin = JSON.parse(JSON.stringify(this.validShangpin))
      validShangpin.forEach(x => {
        x.list.forEach(y => {
          goods.push(y)
        })
      })
      const checked = goods.every(x => x.shopCarIsCheck)
      if (goods.length) {
        this.checked = checked
      } else {
        this.checked = false
      }
    },

    // 点击单选决定是否全选 调用改变数量接口
    checkboxChangeAllchcked (cartId, quantity, shopCarIsCheck) {
      let data = { cartId, quantity, isTick: shopCarIsCheck ? '1' : '0' }
      updateQuantity(data).then(res => {
        if (res.data.code === 0) {
          this.getPage()
        }
      })
    },

    clearShangpin () {
      let ids = []
      let validShangpin = JSON.parse(JSON.stringify(this.validShangpin))
      validShangpin.forEach(x => {
        x.list.forEach(y => {
          if (y.shopCarIsCheck) {
            ids.push(y.id)
          }
        })
      })
      if (!ids.length) {
        Toast('请选择删除的商品')
        return
      }
      Dialog.confirm({
        title: '',
        message: `确定将这${ids.length}件商品删除？`,
        confirmButtonText: '删除'
      }).then(() => {
        delCart(ids).then(res => {
          if (res.data.code === 0) {
            this.getPage()
          }
        })
      }).catch(() => {
      })
    },

    clearLoseShangpin () {
      let ids = []
      this.lose_shangpin.forEach(x => {
        ids.push(x.id)
      })
      Dialog.confirm({
        title: '',
        message: '确认清空所有失效商品？',
        confirmButtonText: '清空'
      }).then(() => {
        this.loading = true
        delCart(ids).then(res => {
          if (res.data.code === 0) {
            this.getPage()
            this.loading = false
          }
        })
      }).catch(() => {
      })
    },

    // 单选
    checkbox (y) {
      if (y.shopCarIsCheck) {
        this.ids.push(y.id)
      } else {
        let index = this.ids.findIndex(x => x == y.id)
        this.ids.splice(index, 1)
      }
      this.checkboxChangeAllchcked(y.id, y.quantity, y.shopCarIsCheck)
    },

    allChecked () {
      this.$nextTick(() => {
        const checked = !this.checked
        let goods = []
        let validShangpin = JSON.parse(JSON.stringify(this.validShangpin))
        validShangpin.forEach(x => {
          x.list.forEach(y => {
            if (checked == y.shopCarIsCheck) {
              goods.push(y)
            }
          })
        })

        goods.forEach((x, i) => {
          let data = { cartId: x.id, quantity: x.quantity, isTick: x.shopCarIsCheck ? '0' : '1' }
          updateQuantity(data).then(res => {
            if (res.data.code === 0) {
              if (goods.length - 1 == i) {
                this.getPage()
              }
            }
          })
        })
      })
    },

    updateQuantity (y, quantity) {
      // 改变数量固定选中
      const data = { cartId: y.id, quantity, isTick: '1' }
      updateQuantity(data).then(res => {
        if (res.data.code === 0) {
          this.getPage()
        }
      })
    },

    jiesuan () {
      let goods = []
      let validShangpin = JSON.parse(JSON.stringify(this.validShangpin))
      validShangpin.forEach(x => {
        x.list.forEach(y => {
          if (y.shopCarIsCheck) {
            goods.push(y)
          }
        })
      })

      let guige = []
      goods.forEach(y => {
        guige.push({
          spuId: y.goodsSku.spuId,
          skuId: y.goodsSku.id,
          count: y.quantity,
          ge_id: y.shopId
        })
      })
      if (!goods.length) {
        Toast('请选择商品')
        return
      }
      this.$router.push({
        path: '/ydzhifu',
        query: {
          guige: JSON.stringify(guige),
          session: this.$route.query.session,
          affiliation: this.$route.query.affiliation,
          id: this.ids2.join(),
          shopIds: this.shopIds.join(),
          ticketActive: this.$route.query.ticketActive,
          advertisementId: this.$route.query.advertisementId,
          advertisementType: this.$route.query.advertisementType
        }
      })
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__left {
  padding: 0 0.2703rem;
}
/deep/ .van-nav-bar__text {
  color: #000;
}
.kong {
  width: 50%;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
}
.shop {
  margin-top: 0.2703rem;
  &-item {
    width: 97.5%;
    background: #fff;
    margin: 0 auto 0.2703rem;
    border-radius: 3px;
    padding: 0.2703rem 0.2703rem 0;
    box-sizing: border-box;
    &-title {
      display: flex;
      align-items: center;
      &-shopName {
        font-size: 0.3784rem;
        color: #333;
        margin: 0 0.1351rem 0 0.2703rem;
      }
    }
    &-shangpin {
      padding: 0.2703rem 0;
      &-checkbox {
        display: flex;
        align-items: center;
      }
      &-stepper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .shop-xiangqing {
        margin-left: 0.2703rem;
        display: flex;
        &-miaoshu {
          margin-left: 0.4054rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 0.3784rem;
          color: #333;
          &-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
.shixiaoshangpin {
  width: 95%;
  margin: auto;
  padding-bottom: 1.6216rem;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 0.4054rem 0;
    &-right {
      display: flex;
      margin-left: 0.1351rem;
      flex: 1;
      &-info {
        flex: 1;
        padding: 0.0811rem 0;
        margin-left: 0.1351rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}
.footer {
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.2703rem 0.5405rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-total {
    display: flex;
    align-items: center;
    .van-button--small {
      height: 0.973rem;
      padding: 0 0.6757rem;
    }
  }
}
</style>